
import swal from "sweetalert2";

export const alertar = (type, titulo, texto) => {

    if (type === "basic") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-primary",
        },
      });
    } else if (type === "info") {
      swal.fire({
        icon: "info",
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-info",
        },
      });
    } else if (type === "success") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-success",
        },
        icon: "success",
      });
    } else if (type === "warning") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-warning",
        },
        icon: "warning",
      });
    } else if (type === "question") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-default",
        },
        icon: "question",
      });
    }
}

import api from '@/servicos/api'

export default {
  registrar(body){
    return api().post('/notificacao', body)
  },
  buscar(body){
    return api().post('/notificacao/select', body)
  },
  visualizar(dados){
    return api().post('/notificacao/visualizar', dados)
  }
}