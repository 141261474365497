<template>
  <v-app-bar
    :color="background"
    dark
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    :class="{ 'border-bottom': !hasBg, 'border-bottom-dark': hasBg ,'bg-gradient-info': true }"
    flat
  >
    <v-row>
      <v-col cols="12" sm="6">
      
      </v-col>

      <v-col cols="12" sm="6" class="text-right">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
          </div>
        </v-btn>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="420"
          max-width="420"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16">ni ni-bell-55</v-icon>
              <span class="numero-notificacao">{{notificacoes.length}}</span>
            </v-btn>
          </template>

          <v-list class="py-0 list-item-notificacao">
            <div class="pa-4">
              <h6 class="text-body-2 ls-0 font-weight-600 text-muted m-0">
                Você tem <strong class="text-primary">{{notificacoes.length}}</strong> notifications.
              </h6>
            </div>

            <v-list-item
              v-for="(item, i) in notificacoes"
              :key="i"
              class="pa-4 list-item-hover-active "
              :class="{ 'border-bottom': i != notificacoes.length - 1 }"
            >
              <v-list-item-avatar :size="48" class="my-0 me-5">
                <!-- <v-img
                  :alt="`${item.avatar} avatar`"
                  :src="item.avatar"
                ></v-img> -->
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      {{ item.titulo }}
                    </v-col>
                    <v-col class="text-right">
                      <span class="text-muted text-body-2 text-caption ls-0">
                        2 hrs ago
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>

                <p
                  class="mt-1 mb-0 text-body text-body-2 ls-0 font-weight-thin"
                >
                  {{ item.mensagem }}
                </p>
              </v-list-item-content>
            </v-list-item>

            <a
              href="javascript:;"
              @click="() => link('/list/notification')"
              class="text-decoration-none text-primary text-body-2 ls-0 pa-4 text-center font-weight-600 d-block list-item-hover-active"
              >Ver Tudo</a
            >
          </v-list>
        </v-menu>

        <v-menu
          transition="slide-y-transition"
          offset-y
          min-width="350"
          max-width="350"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16">ni ni-ungroup</v-icon>
            </v-btn>
          </template>

          <v-card class="bg-default">
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="4" v-for="item in dropdown2" :key="item.name">
                  <div class="cursor-pointer d-flex flex-column align-center">
                    <v-avatar :color="item.bg" class="shortcut-icon">
                      <v-icon size="16" class="text-white">
                        {{ item.icon }}
                      </v-icon>
                    </v-avatar>
                    <span class="font-weight-600 text-white mt-3">{{
                      item.name
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu transition="slide-y-transition" offset-y :position-x="50">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :ripple="false"
              class="font-weight-600 ls-0 text-capitalize pe-0"
              color="transparent"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                width="36"
                src="@/assets/img/team-4.jpg"
                class="me-2 rounded-circle"
              ></v-img>
              <span
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
                >{{nomeUsuario}}</span
              >
            </v-btn>
          </template>

          <v-list min-width="192" max-width="192">
            <h6
              class="text-h6 px-4 font-weight-600 text-typo text-uppercase py-2"
            >
              Welcome!
            </h6>

            <v-list-item
              link
              :ripple="false"
              v-for="(item, i) in dropdown3"
              :key="i" @change="acaoDropdownUsuario(item)"
              class="min-height-auto"
              :class="{ 'border-bottom': i == dropdown3.length - 2 }"
            >
              <v-list-item-avatar
                class="my-0 me-5"
                width="15"
                min-width="15"
                height="37"
              >
                <v-icon class="text-darker">{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-darker">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item-content>

              <div class="border-bottom"></div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import {logout} from '@/servicos/autenticacao.servicos.js'
import NotificacaoServico from '../servicos/notificacao.servico';
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
  },
  data() {
    return {
      nomeUsuario:localStorage.getItem("nome"),
      drawer: false,
      togglerActive: false,
      dropdown3: [
        // {
        //   name: "My profile",
        //   icon: "ni-single-02",
        // },
        // {
        //   name: "Settings",
        //   icon: "ni-settings-gear-65",
        // },
        // {
        //   name: "Activity",
        //   icon: "ni-calendar-grid-58",
        // },
        // {
        //   name: "Support",
        //   icon: "ni-support-16",
        // },
        {
          name: "Logout",
          icon: "ni-user-run",
        },
      ],
      dropdown2: [
        {
          name: "Calendar",
          icon: "ni-calendar-grid-58",
          bg: "bg-gradient-danger",
        },
        {
          name: "Email",
          icon: "ni-email-83",
          bg: "bg-gradient-warning",
        },
        {
          name: "Payments",
          icon: "ni-credit-card",
          bg: "bg-gradient-info",
        },
        {
          name: "Reports",
          icon: "ni-books",
          bg: "bg-gradient-success",
        },
        {
          name: "Maps",
          icon: "ni-pin-3",
          bg: "bg-gradient-purple",
        },
        {
          name: "Shop",
          icon: "ni-basket",
          bg: "bg-gradient-yellow",
        },
      ],
      dropdown1: [
        {
          avatar: require("@/assets/img/team-1.jpg"),
          title: "John Snow",
          description: "Let's meet at Starbucks at 11:30. Wdyt?",
        },
        {
          avatar: require("@/assets/img/team-2.jpg"),
          title: "Alex Smith",
          description: "A new issue has been reported for Argon.",
        },
        {
          avatar: require("@/assets/img/team-3.jpg"),
          title: "Samantha Ivy",
          description: "Your posts have been liked a lot.",
        },
        {
          avatar: require("@/assets/img/team-4.jpg"),
          title: "Ali Connors",
          description: "Let's meet at Starbucks at 11:30. Wdyt?",
        },
        {
          avatar: require("@/assets/img/team-1.jpg"),
          title: "John Snow",
          description: "Let's meet at Starbucks at 11:30. Wdyt?",
        },
      ],
      notificacoes: []
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    acaoDropdownUsuario(item){
      if(item.name.toUpperCase() == 'LOGOUT'){
        logout(this.$router)
      }
    },
    async buscarNotificacoes(){
      this.notificacoes = await NotificacaoServico.buscar({idusuario: parseInt(localStorage.getItem('idusuario'))}).then(res => res.data)
    },
    link(to) {
      this.$router.push(to);
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
  async mounted(){
    let idusuario = localStorage.getItem('idusuario')
    let token = localStorage.getItem('tkpontua')
    await this.buscarNotificacoes()
    if(!idusuario || !token){
        localStorage.clear();
        this.$router.push('/login')
    }

    //Conexao Socket
    this.$socket.disconnect();
    this.$socket.io.opts.query.email = 'rw'
    this.$socket.io.opts.query.tipo = 'USUARIOPONTUA'
    let testw = this.$socket.connect();
 

    this.sockets.subscribe("notificar", (dados) => {
      alert(dados.msg)
      
    });

  }
};
</script>
<style lang="scss">
.numero-notificacao{
  background-color: red !important;
  width: 1.5em !important;
  height: 1.5em !important;
  color: white !important;
  position: relative !important;
  bottom: -5px !important;
  border: none !important;
  border-radius: 50% !important;
  display: grid !important;
  place-items: center !important;
}

.list-item-notificacao{
  overflow-y: scroll;
  max-height: 80vh;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }  
}
</style>
