import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: 'https://apieshotel.pontua.economysoftware.com.br/',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*'
    }
  })
}


// export default () => {
//   return axios.create({
//     baseURL: 'http://localhost:3000/',
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//       'Access-Control-Allow-Origin': '*'
//     }
//   })
// }