import api from '@/servicos/api'

export default {
  listarTodos(){
    return api().get('/navegacao/select')
  },
  listarPorTipoUsuario(id){
    return api().post('/navegacao/select', {idusuariotipo: id})
  },
  vincular(body){
    return api().post('/navegacao', body)
  }
}