import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const AlternativeDashboard = () =>
  import("../views/Dashboard/AlternativeDashboard.vue");
const Calendar = () => import("../views/Dashboard/Calendar.vue");
const Charts = () => import("../views/Dashboard/Charts.vue");
const Widgets = () => import("../views/Dashboard/Widgets.vue");

// Components pages
const Buttons = () => import("../views/Components/Buttons.vue");
const Grid = () => import("../views/Components/Grid.vue");
const Icons = () => import("../views/Components/Icons.vue");
const Typography = () => import("../views/Components/Typography.vue");
const Cards = () => import("../views/Components/Cards.vue");
const Notifications = () => import("../views/Components/Notifications.vue");

// Maps pages
const GoogleMaps = () => import("../views/Maps/Google.vue");
const VectorMaps = () => import("../views/Maps/Vector.vue");

// Tables pages
const RegularTables = () => import("../views/Tables/RegularTables.vue");
const SortableTables = () => import("../views/Tables/SortableTables.vue");
const PaginatedTables = () => import("../views/Tables/PaginatedTables.vue");

// Forms pages
const FormElements = () => import("../views/Forms/FormElements.vue");
const FormComponents = () => import("../views/Forms/FormComponents.vue");
const FormValidation = () => import("../views/Forms/FormValidation.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");

const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");

const RegisterReserve = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterReserve.vue");
const ListReserve = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListReserve.vue");
const ReadReserve = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadReserve.vue");

const RegisterHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterHotel.vue");
const ListHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListHotel.vue");
const ReadHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadHotel.vue");
const ShowHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowHotel.vue");

const ListRegra = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListRegra.vue");
const RegisterRegra = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterRegra.vue");

const RegisterOperator = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterOperator.vue");
const ListOperator = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListOperator.vue");
const ReadOperator = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadOperator.vue");

const RegisterPromoter = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterPromoter.vue");
const ListPromoter = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListPromoter.vue");
const ReadPromoter = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadPromoter.vue");

const RegisterAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterAgent.vue");
const ListAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListAgent.vue");
const ReadAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadAgent.vue");

const RegisterAgency = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterAgency.vue");
const ListAgency = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListAgency.vue");
const ReadAgency = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadAgency.vue");

const RegisterRequest = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterRequest.vue");
const ListRequest = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListRequest.vue");
const ReadRequest= () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadRequest.vue");
const ShowRequest= () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowRequest.vue");

const RegisterAward = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterAward.vue");
const ListAward = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListAward.vue");
const ReadAward = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadAward.vue");

const RegisterZone = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterZone.vue");

const RegisterRule = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterRule.vue");
const ListRule = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListRule.vue");
const ReadRule = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadRule.vue");
const ShowRule = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowRule.vue");


const RegisterPromo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterPromo.vue");
const ListPromo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListPromo.vue");
const ReadPromo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadPromo.vue");
  const ShowPromo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowPromo.vue");
  
 const RegisterProfileAccess = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterProfileAccess.vue");
  
  
const RegisterPromoAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterPromoAgent.vue");
  const ListPromoAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListPromoAgent.vue");
  const ReadPromoAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadPromoAgent.vue");
  const ShowPromoAgent = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowPromoAgent.vue");
  
  
const RegisterKit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterKit.vue");
  const ListKit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListKit.vue");
  const ReadKit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadKit.vue");
  const ShowKit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ShowKit.vue");
  
  
const ListAdmHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListAdmHotel.vue");
const ReadAdmHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ReadAdmHotel.vue");
const RegisterAdmHotel = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterAdmHotel.vue");


const RegisterConfigLevels = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/RegisterConfigLevels.vue");
const ListConfigLevels = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListConfigLevels.vue");

  
const ListNotification = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListNotification.vue");


const ListScore = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/ListScore.vue");



const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
const Profile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Timeline.vue");
const Recovery = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Recovery.vue");
const AgencyRegister = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/AgencyRegister.vue");


const Test = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Test.vue");

Vue.use(VueRouter);

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "grid",
      name: "Grid",
      components: { default: Grid },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
      meta: {
        groupName: "Components",
      },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  redirect:'/login',
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/register/reserve",
      name: 'RegisterReserve',
      component: RegisterReserve
    },
    {
      path: "/agencyregister",
      name: "AgencyRegister",
      component: AgencyRegister,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
    {
      path: "/recovery",
      name: "Recovery",
      component: Recovery,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "/maps/google",
      name: "Google",
      components: { default: GoogleMaps },
      meta: {
        groupName: "Maps",
      },
    },
    {
      path: "/maps/vector",
      name: "Vector",
      components: { default: VectorMaps },
      meta: {
        groupName: "Maps",
      },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Elements",
      components: { default: FormElements },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "components",
      name: "Components",
      components: { default: FormComponents },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "validation",
      name: "Validation",
      components: { default: FormValidation },
      meta: {
        groupName: "Forms",
      },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Regular",
      components: { default: RegularTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
      meta: {
        groupName: "Tables",
      },
    },
  ],
};

const routes = [
  {
    path: "/dash",
    name: "Dashboard",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/alternative",
        name: "Alternative",
        component: AlternativeDashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/profile",
        name: "Profile",
        components: { default: Profile },
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/timeline",
        name: "Timeline",
        components: { default: Timeline },
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/register/reserve",
        name: 'RegisterReserve',
        component: RegisterReserve
      },
      {
        path: "/list/reserve",
        name: 'ListReserve',
        component: ListReserve
      },
      {
        path: '/list/reserve/:id',
        name: 'ReadReserve',
        component: ReadReserve
      },
      {
        path: "/register/hotel",
        name: 'RegisterHotel',
        component: RegisterHotel
      },
      {
        path: "/list/hotel",
        name: 'ListHotel',
        component: ListHotel
      },
      {
        path: '/list/hotel/:id',
        name: 'ReadHotel',
        component: ReadHotel
      },
      {
        path: "/show/hotel/:id",
        name: 'ShowHotel',
        component: ShowHotel
      },
      {
        path: "/list/regras",
        name: 'ListRegra',
        component: ListRegra
      },
      {
        path: "/register/regra",
        name: 'RegisterRegra',
        component: RegisterRegra
      },
      {
        path: '/register/operator',
        name: 'RegisterOperator',
        component: RegisterOperator
      },
      {
        path: '/list/operator',
        name: 'ListOperator',
        component: ListOperator
      },
      {
        path: '/list/operator/:id',
        name: 'ReadOperator',
        component: ReadOperator
      },
      {
        path: '/register/promoter',
        name: 'RegisterPromoter',
        component: RegisterPromoter
      },
      {
        path: '/list/promoter',
        name: 'ListPromoter',
        component: ListPromoter
      },
      {
        path: '/list/promoter/:id',
        name: 'ReadPromoter',
        component: ReadPromoter
      },
      {
        path: '/register/agent',
        name: 'RegisterAgent',
        component: RegisterAgent
      },
      {
        path: '/list/agent',
        name: 'ListAgent',
        component: ListAgent
      },
      {
        path: '/list/agent/:id',
        name: 'ReadAgent',
        component: ReadAgent
      },
      {
        path: '/register/agency',
        name: 'RegisterAgency',
        component: RegisterAgency
      },
      {
        path: '/list/agency',
        name: 'ListAgency',
        component: ListAgency
      },
      {
        path: '/list/agency/:id',
        name: 'ReadAgency',
        component: ReadAgency
      },
      {
        path: '/register/request',
        name: 'RegisterRequest',
        component: RegisterRequest
      },
      {
        path: '/list/request',
        name: 'ListRequest',
        component: ListRequest
      },
      {
        path: '/list/request/:id',
        name: 'ReadRequest',
        component: ReadRequest
      },
      {
        path: '/show/request/:id',
        name: 'ShowRequest',
        component: ShowRequest
      },
      {
        path: '/register/award',
        name: 'RegisterAward',
        component: RegisterAward
      },
      {
        path: '/list/award',
        name: 'ListAward',
        component: ListAward
      },
      {
        path: '/list/award/:id',
        name: 'ReadAward',
        component: ReadAward
      },
      {
        path: '/register/zone',
        name: 'RegisterZone',
        component: RegisterZone
      },
      {
        path: '/register/rule',
        name: 'RegisterRule',
        component: RegisterRule
      },
      {
        path: '/list/rule',
        name: 'ListRule',
        component: ListRule
      },
      {
        path: '/list/rule/:id',
        name: 'ReadRule',
        component: ReadRule
      },
      {
        path: '/show/rule/:id',
        name: 'ShowRule',
        component: ShowRule
      },
      {
        path: '/register/promo',
        name: 'RegisterPromo',
        component: RegisterPromo
      },
      {
        path: '/list/promo',
        name: 'ListPromo',
        component: ListPromo
      },
      {
        path: '/list/promo/:id',
        name: 'ReadPromo',
        component: ReadPromo
      },
      {
        path: '/show/promo/:id',
        name: 'ShowPromo',
        component: ShowPromo
      },
      {
        path: '/profile-access',
        name: 'RegisterProfileAccess',
        component: RegisterProfileAccess
      },
      {
        path: '/register/promo-agent',
        name: 'RegisterPromoAgent',
        component: RegisterPromoAgent
      },
      {
        path: '/list/promo-agent',
        name: 'ListPromoAgent',
        component: ListPromoAgent
      },
      {
        path: '/list/promo-agent/:id',
        name: 'ReadPromoAgent',
        component: ReadPromoAgent
      },
      {
        path: '/show/promo-agent/:id',
        name: 'ShowPromoAgent',
        component: ShowPromoAgent
      },
      {
        path: '/register/kit',
        name: 'RegisterKit',
        component: RegisterKit
      },
      {
        path: '/list/kit',
        name: 'ListKit',
        component: ListKit
      },
      {
        path: '/list/kit/:id',
        name: 'ReadKit',
        component: ReadKit
      },
      {
        path: '/show/kit/:id',
        name: 'ShowKit',
        component: ShowKit
      },
      {
        path: '/list/admhotel',
        name: 'ListAdmHotel',
        component: ListAdmHotel
      },
      {
        path: '/list/admhotel/:id',
        name: 'ReadAdmHotel',
        component: ReadAdmHotel
      },
      {
        path: '/register/admhotel',
        name: 'RegisterAdmHotel',
        component: RegisterAdmHotel
      },
      {
        path: '/config/levels',
        name: 'RegisterConfigLevels',
        component: RegisterConfigLevels
      },
      {
        path: '/list/levels',
        name: 'ListConfigLevels',
        component: ListConfigLevels
      },
      {
        path: '/list/score',
        name: 'ListScore',
        component: ListScore
      },
      {
        path: '/list/notification',
        name: 'ListNotification',
        component: ListNotification
      },
      {
        path: '/icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: '/teste',
        name: 'Test',
        component: Test
      },
    ],
  },
  componentsMenu,
  authPages,
  mapsMenu,
  tablesMenu,
  formsMenu,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
