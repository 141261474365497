
import { alertar } from '@/servicos/notificacao.servico.js'
import api from '@/servicos/api'
export const logar = (email, senha) => {
    return new Promise((resolve, reject) => {
        api().post("login", {
            email: email,
            senha: senha
        }).then(resp => {
            let token = resp.data.token
            let usuario = resp.data.dados

            localStorage.setItem("tkpontua", token)
            localStorage.setItem("idusuario", usuario.idusuario)
            localStorage.setItem("email", usuario.email)
            localStorage.setItem("nome", usuario.nome)
            localStorage.setItem("idstatususuario", usuario.idstatususuario)
            localStorage.setItem("idusuariotipo", usuario.idusuariotipo)
            localStorage.setItem("idagencia", usuario.idagencia)
            localStorage.setItem("nomeagencia", usuario.nomeagencia)

            resolve(resp)

        }).catch(erro => {
            console.log('error login', erro)
            console.log('error login', erro.json())

            alertar("warning", "=(", "Login ou Senha não Encontrado")
            reject(error)
        })
    })


}

export const logout = (router) => {
    try{
        localStorage.clear()
        router.push("/login")
    }catch(e){
        console.log('error logout', e)
    }
}