<template>
    <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    class="elevation-0 navigation-zindex"
    
  >
    <v-list-item class="pa-0" v-if="!loading">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <img src="@/assets/img/green.png" class="w-70" />
          </div>

          <div
            class="drawer-toggler pa-5 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense v-if="!loading">
      <v-list-group
        :ripple="false"
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        append-icon="fas fa-angle-down me-2"
        class="pb-1"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-0 no-default-hover"
          v-for="child in item.items"
          :key="child.title"
          :to="child.link"
        >
          <span
            class="v-list-item-mini d-flex justify-center my-2"
            v-text="child.prefix"
          ></span>

          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.title === 'Components'"
          prepend-icon=""
          :ripple="false"
          sub-group
          no-action
        >
          <template v-slot:activator>
            <span class="v-list-item-mini d-flex justify-center my-2">ML</span>
            <v-list-item-content>
              <v-list-item-title>Multi Level</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in thirdLevelSimple"
            :key="i"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-group>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsSimple"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsDocs"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  <NavSkeleton v-if="loading"/>
  </v-navigation-drawer>
</template>
<script>
import NavegacaoServico from '../servicos/navegacao.servico.js'
import NavSkeleton from './Loading/NavSkeleton.vue'
export default {
  components: {NavSkeleton},
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    mini: false,
    togglerActive: false,
    tipousuario: null,
    itemsDocs: [
      // {
      //   action: "ni ni-spaceship v-icon-drawer",
      //   link: "https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation",
      //   title: "Getting Started",
      // },
      // {
      //   action: "ni ni-palette v-icon-drawer",
      //   link: "https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html",
      //   title: "Foundation",
      // },
      // {
      //   action: "ni ni-ui-04 v-icon-drawer",
      //   link: "https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html",
      //   title: "Components",
      // },
      // {
      //   action: "ni ni-chart-pie-35 v-icon-drawer",
      //   link: "https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html",
      //   title: "Plugins",
      // },
    ],
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    itemsSimple: [
     
    ],
    // items: [
    //   {
    //     action: "ni-single-copy-04 v-icon-drawer text-primary",
    //     items: [
    //       { title: "Todos os hotéis", prefix: "", link: "/list/hotel" },
    //       { title: "Criar novo hotel", prefix: "", link: "/register/hotel" },
    //     ],
    //     active: false,
    //     title: "Hotel",
    //   },
    //   {
    //     action: "ni-align-left-2 v-icon-drawer text-primary",
    //     items: [
    //       { title: "Todas as reservas", prefix: "", link: "/list/reserve" },
    //       {
    //         title: "Criar nova reserva",
    //         prefix: "",
    //         link: "/register/reserve",
    //       },
    //     ],
    //     active: false,
    //     title: "Reserva",
    //   },
    //   // {
    //   //   action: "ni-shop v-icon-drawer text-primary",
    //   //   items: [
    //   //     { title: "Dashboard", prefix: "D", link: "/dashboard" },
    //   //     { title: "Alternative", prefix: "A", link: "/alternative" },
    //   //   ],
    //   //   active: true,
    //   //   title: "Dashboards",
    //   // },
    //   // {
    //   //   action: "ni-ungroup v-icon-drawer text-warning",
    //   //   items: [
    //   //     { title: "Pricing", prefix: "P", link: "/pricing" },
    //   //     { title: "Login", prefix: "L", link: "/login" },
    //   //     { title: "Register", prefix: "R", link: "/register" },
    //   //     { title: "Lock", prefix: "L", link: "/lock" },
    //   //     { title: "Timeline", prefix: "T", link: "/timeline" },
    //   //     { title: "Profile", prefix: "P", link: "/profile" },
    //   //   ],
    //   //   title: "Examples",
    //   // },
    //   // {
    //   //   action: "ni-ui-04 v-icon-drawer text-info",
    //   //   items: [
    //   //     { title: "Buttons", prefix: "B", link: "/components/buttons" },
    //   //     { title: "Cards", prefix: "C", link: "/components/cards" },
    //   //     { title: "Grid", prefix: "G", link: "/components/grid" },
    //   //     {
    //   //       title: "Notifications",
    //   //       prefix: "N",
    //   //       link: "/components/notifications",
    //   //     },
    //   //     { title: "Icons", prefix: "I", link: "/components/icons" },
    //   //     { title: "Typography", prefix: "T", link: "/components/typography" },
    //   //   ],
    //   //   title: "Components",
    //   // },
    //   // {
    //   //   action: "ni-single-copy-04 v-icon-drawer text-pink",
    //   //   items: [
    //   //     { title: "Elements", prefix: "E", link: "/forms/elements" },
    //   //     { title: "Components", prefix: "C", link: "/forms/components" },
    //   //     { title: "Validation", prefix: "V", link: "/forms/validation" },
    //   //   ],
    //   //   title: "Forms",
    //   // },
    //   // {
    //   //   action: "ni-align-left-2 v-icon-drawer text-default",
    //   //   items: [
    //   //     { title: "Tables", prefix: "T", link: "/tables/regular" },
    //   //     { title: "Sortable", prefix: "T", link: "/tables/sortable" },
    //   //     {
    //   //       title: "Paginated Tables",
    //   //       prefix: "PT",
    //   //       link: "/tables/paginated",
    //   //     },
    //   //   ],
    //   //   title: "Tables",
    //   // },
    //   // {
    //   //   action: "ni-map-big v-icon-drawer text-primary",
    //   //   items: [
    //   //     { title: "Google", prefix: "G", link: "/maps/google" },
    //   //     { title: "Vector", prefix: "V", link: "/maps/vector" },
    //   //   ],
    //   //   title: "Maps",
    //   // },
    // ],
  }),
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    async requisitarRotas(){
        const navPorTipo = await NavegacaoServico.listarPorTipoUsuario(this.tipousuario)
        .then(result => result.data)
      const navList = navPorTipo.map(item => item.idnavegacao)
      
      const navDefault = await NavegacaoServico.listarTodos().then(result => result.data)

      this.itemsSimple = navDefault.filter(el => navList.includes(el.idnavegacao)).map(item => {
          let retorno =  {}
          retorno.action = item.icone
        
          retorno.link = item.link == '/dash'  ? '/dash/dashboard' :item.link
          retorno.title = item.titulo
          return retorno
      })
      
      // .map(item => ({
      //   action: item.icone,
      //   link: item.link,
      //   title: item.titulo
      // }))
    
    }
  },
  async mounted(){
    this.loading = true
    this.tipousuario = parseInt(localStorage.getItem('idusuariotipo'))
    await this.requisitarRotas()
    this.loading = false
  }
};
</script>
<style lang="scss">
.navigation-zindex {
  z-index: 1000;
}
.v-list-item__title{
  color: rgba(0,0,0,0.95)
}

.v-navigation-drawer__content{
  &::-webkit-scrollbar {
        display: none;
      }
}
</style>