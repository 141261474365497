var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"py-4 px-2 toolbar-content-padding-y-none",class:{ 'border-bottom': !_vm.hasBg, 'border-bottom-dark': _vm.hasBg ,'bg-gradient-info': true },attrs:{"color":_vm.background,"dark":"","height":"auto","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm",class:{
          'btn-dark-toggler-hover': !_vm.hasBg,
          'btn-toggler-hover': _vm.hasBg,
          active: _vm.togglerActive,
        },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }}),_c('i',{staticClass:"drawer-toggler-line",class:{ 'bg-white': _vm.hasBg }})])]):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"420","max-width":"420"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"icon":"","ripple":false,"color":_vm.linkColor}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("ni ni-bell-55")]),_c('span',{staticClass:"numero-notificacao"},[_vm._v(_vm._s(_vm.notificacoes.length))])],1)]}}])},[_c('v-list',{staticClass:"py-0 list-item-notificacao"},[_c('div',{staticClass:"pa-4"},[_c('h6',{staticClass:"text-body-2 ls-0 font-weight-600 text-muted m-0"},[_vm._v(" Você tem "),_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.notificacoes.length))]),_vm._v(" notifications. ")])]),_vm._l((_vm.notificacoes),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-4 list-item-hover-active ",class:{ 'border-bottom': i != _vm.notificacoes.length - 1 }},[_c('v-list-item-avatar',{staticClass:"my-0 me-5",attrs:{"size":48}}),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(item.titulo)+" ")]),_c('v-col',{staticClass:"text-right"},[_c('span',{staticClass:"text-muted text-body-2 text-caption ls-0"},[_vm._v(" 2 hrs ago ")])])],1)],1),_c('p',{staticClass:"mt-1 mb-0 text-body text-body-2 ls-0 font-weight-thin"},[_vm._v(" "+_vm._s(item.mensagem)+" ")])],1)],1)}),_c('a',{staticClass:"text-decoration-none text-primary text-body-2 ls-0 pa-4 text-center font-weight-600 d-block list-item-hover-active",attrs:{"href":"javascript:;"},on:{"click":function () { return _vm.link('/list/notification'); }}},[_vm._v("Ver Tudo")])],2)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","min-width":"350","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"icon":"","ripple":false,"color":_vm.linkColor}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("ni ni-ungroup")])],1)]}}])},[_c('v-card',{staticClass:"bg-default"},[_c('v-card-text',{staticClass:"card-padding"},[_c('v-row',_vm._l((_vm.dropdown2),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"4"}},[_c('div',{staticClass:"cursor-pointer d-flex flex-column align-center"},[_c('v-avatar',{staticClass:"shortcut-icon",attrs:{"color":item.bg}},[_c('v-icon',{staticClass:"text-white",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('span',{staticClass:"font-weight-600 text-white mt-3"},[_vm._v(_vm._s(item.name))])],1)])}),1)],1)],1)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","position-x":50},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-600 ls-0 text-capitalize pe-0",attrs:{"depressed":"","ripple":false,"color":"transparent"}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"me-2 rounded-circle",attrs:{"width":"36","src":require("@/assets/img/team-4.jpg")}}),_c('span',{class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },style:(!_vm.hasBg ? 'color: rgba(0,0,0, .6)' : '')},[_vm._v(_vm._s(_vm.nomeUsuario))])],1)]}}])},[_c('v-list',{attrs:{"min-width":"192","max-width":"192"}},[_c('h6',{staticClass:"text-h6 px-4 font-weight-600 text-typo text-uppercase py-2"},[_vm._v(" Welcome! ")]),_vm._l((_vm.dropdown3),function(item,i){return _c('v-list-item',{key:i,staticClass:"min-height-auto",class:{ 'border-bottom': i == _vm.dropdown3.length - 2 },attrs:{"link":"","ripple":false},on:{"change":function($event){return _vm.acaoDropdownUsuario(item)}}},[_c('v-list-item-avatar',{staticClass:"my-0 me-5",attrs:{"width":"15","min-width":"15","height":"37"}},[_c('v-icon',{staticClass:"text-darker"},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-darker"},[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"border-bottom"})],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }