import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueMask from 'v-mask'
import { VueMaskFilter } from 'v-mask'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import dayjs from 'dayjs'
import {
  uniScenery, uniCheckCircle, uniLocationPoint, uniFavorite, uniSync, uniSpinner,
  uniSearch, uniArrowCircleRight, uniArrowCircleLeft, uniPostcard,
  uniHome, uniTrophy, uniAward, uniAwardAlt, uniCalender, uniAngleDown,
  uniClockEight, uniMoon, uniThumbsUp, uniRestaurant, uniGift,uniFilesLandscapesAlt,
  uniMobileAndroid, uniLaptop, uniTablet, uniAppleAlt, uniCardAtm, uniEstate, uniFacebook, uniWhatsapp,
} from 'vue-unicons/dist/icons'

Unicon.add([uniScenery, uniCheckCircle, uniLocationPoint, uniFavorite, uniPostcard, uniSync, uniSpinner,
  uniSearch, uniArrowCircleRight, uniArrowCircleLeft, uniHome, uniGift,
  uniTrophy, uniAward, uniAwardAlt, uniCalender, uniAngleDown,uniFilesLandscapesAlt,
  uniMobileAndroid, uniLaptop, uniTablet, uniAppleAlt, uniCardAtm, uniEstate, uniFacebook, uniWhatsapp,
  uniClockEight, uniMoon, uniThumbsUp, uniRestaurant])

Vue.use(Unicon)
Vue.use(VueMask);

Vue.filter('VMask', VueMaskFilter)
Vue.filter('formatarData', function(value) {
    if (value) {
        // return moment(String(value)).format('DD/MM/YYYY')
        return dayjs(String(value)).format('DD/MM/YYYY')
    }
})

Vue.filter('formatarDataHora', function(value) {
    if (value) {
        // return moment(String(value)).format('DD/MM/YYYY HH:mm')
        return dayjs(String(value)).format('DD/MM/YYYY HH:mm')
    }
})
Vue.filter('formatarDataYYHora', function(value) {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YY HH:mm')
    }
})
Vue.filter('formatarDataDDMM', function(value) {
    if (value) {
        return dayjs(String(value)).format('DD/MM')
    }
})
// Vue.filter('formatarMoeda_backup', function(value) {
//     if (value) {
//         return parseFloat(value).toLocaleString()
//     } else {
//         return '0,00'
//     }
// })
Vue.filter('formatarMoeda', function(value) {
    if (value) {
        var v = parseFloat(value).toFixed(2);
        v = v.replace(".", ",");
        v = v.replace(/,(\d)\b/g, ",$10");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        return v;
    } else {
        return '0,00'
    }
})

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);


import { APIURL } from '@/../constantes.js'
import axios from 'axios'
axios.defaults.baseURL = APIURL
axios.defaults.headers.get['Accept'] = 'application/json; charset=utf-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const tokenrecuperado = localStorage.getItem('token')
if (tokenrecuperado) {
  axios.defaults.headers.common['x-access-token'] = tokenrecuperado
}


import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client";


Vue.use(
    new VueSocketIO({
        debug: true,
        connection: SocketIO(axios.defaults.baseURL + '/pontua', { query: {}, autoConnect: false }),
        vuex: {
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_",
        },
        // options: [{ transports: ["websocket"] }],
    })
);


Vue.prototype.$http = axios


new Vue({
  router,
  vuetify,
  sockets: {
        connect: function() {
            console.log('conectado')
        },
        disconnect: function() {
            console.log('nao conectado')
        },
  },
  render: (h) => h(App),
}).$mount("#app");
