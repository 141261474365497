<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
//css indesejados
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

</style>